import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import type {ContainerProps} from '@/modules/foundation/components/container';
import {Container} from '@/modules/foundation/components/container';
import {Heading} from '@/modules/foundation/components/typography/heading';
import type {PortableTextOverrides} from '@/modules/foundation/sanity/portable-text';
import {Badge} from '@/modules/selvklart/components/badge';

type Props = PropsWithChildren &
	ContainerProps & {
		heading?: string;
		badge?: string;
		align?: 'left' | 'center';
	};

export const textHeadingPortableTextOverrides: PortableTextOverrides = {};

export const TextHeading: FC<Props> = ({
	heading,
	children,
	badge,
	align = 'left',
	spacingBottom = 'large',
	spacingTop = 'large',
	...containerProps
}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop} {...containerProps}>
			<div
				className={clsx(
					'max-w-3xl',
					align === 'left' ? 'text-left' : 'mx-auto max-w-[605px] text-center',
				)}
			>
				{badge && (
					<Badge intent="secondary" className="mb-4">
						{badge}
					</Badge>
				)}
				<Heading spacing>{heading}</Heading>
				{children}
			</div>
		</Container>
	);
};
